@media (width <= 767px) {
  .menu-btn {
    display: flex !important;
  }

  .menu-items {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    scrollbar-gutter: stable;
    left: -100vh;
    top: var(--header-height);
    height: calc(100vh - 80px)  - calc(var(--has-banner) * var(--banner-height));
    width: 20rem;
    height: 100%;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    transition: left .3s ease-out;
    display: block;
    position: fixed;
    overflow-y: auto;
  }

  .menu-items.open {
    left: 0;
  }
}

#jumbo {
  place-items: center;
  padding: 4rem 2rem;
  display: grid;
}

@media (width >= 1024px) {
  #jumbo {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-top: 0;
    padding-bottom: 0;
  }
}

header + .container {
  margin-top: var(--header-height);
}

.page-title {
  letter-spacing: -.025em;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--header-height);
  max-width: 1280px;
  padding: 4rem 2rem;
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1;
}

h1.page-title + .container {
  padding-top: 2rem;
}

.container.jumbo {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (width >= 1024px) {
  .container.jumbo {
    flex-direction: row;
  }
}

.container.jumbo {
  margin-top: var(--header-height);
  min-height: calc(100vh - var(--header-height));
}

div.feature-list {
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 1024px) {
  div.feature-list {
    max-width: 56rem;
  }
}

dl.feature-list {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2.5rem 2rem;
  max-width: 36rem;
  display: grid;
}

@media (width >= 1024px) {
  dl.feature-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 4rem;
    max-width: none;
  }
}

dl.feature-list > .item {
  padding-left: 4rem;
  position: relative;
}

dl.feature-list > .item > dt.title {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

dl.feature-list > .item > dd.desc {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  margin-top: .5rem;
  font-size: 1rem;
  line-height: 1.75rem;
}

dl.feature-list > .item > dt.title > .icon {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

dl.feature-list > .item > dt.title > .icon svg {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  width: 1.5rem;
  height: 1.5rem;
}

ol.checklist {
  --tw-border-opacity: 1;
  border-inline-start-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  position: relative;
}

ol.checklist > li {
  margin-bottom: 2.5rem;
  margin-inline-start: 1.5rem;
}

ol.checklist > li > .icon {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: .25rem;
  display: flex;
  position: absolute;
  inset-inline-start: -.625rem;
}

ol.checklist > li > h3.title {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  margin-bottom: .375rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

ol.checklist > li > p.desc {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

#personas {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.personas {
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

@media (width >= 1024px) {
  .personas {
    flex-direction: row;
  }
}

.personas > .persona {
  border-radius: var(--content-radius);
  border-width: 1px;
  border-color: #d4d4d4bf;
  flex-direction: column;
  flex: 1;
  display: flex;
}

@media (width >= 640px) {
  .personas > .persona {
    margin-left: .5rem;
    margin-right: .5rem;
  }
}

@media (width >= 768px) {
  .personas > .persona {
    margin-left: 6rem;
    margin-right: 6rem;
  }
}

@media (width >= 1024px) {
  .personas > .persona {
    margin-left: 0;
    margin-right: 0;
  }
}

.personas > .persona > a.image {
  margin: .5rem;
}

.personas > .persona div.image {
  border-radius: calc(var(--content-radius)  - .375rem);
  background-position: center;
  background-size: auto 100%;
  height: 16rem;
}

@media (width >= 640px) {
  .personas > .persona div.image {
    height: 20rem;
  }
}

@media (width >= 1024px) {
  .personas > .persona div.image {
    height: 18rem;
  }
}

.personas > .persona div.image.merchant {
  background-image: url("merchant-l.53139c7d.webp");
}

@media (width >= 1024px) {
  .personas > .persona div.image.merchant {
    background-image: url("merchant.a80d84b3.webp");
  }
}

.personas > .persona div.image.technician {
  background-image: url("technician-l.0d025705.webp");
}

@media (width >= 1024px) {
  .personas > .persona div.image.technician {
    background-image: url("technician.f66aa8ea.webp");
  }
}

.personas > .persona div.image.integrator {
  background-image: url("developer-l.ce54399e.webp");
}

@media (width >= 1024px) {
  .personas > .persona div.image.integrator {
    background-image: url("developer.149e25b6.webp");
  }
}

@media (-webkit-device-pixel-ratio >= 2), (resolution >= 144dpi) {
  .personas > .persona div.image.merchant {
    background-image: url("merchant-l-2x.2b628394.webp");
  }

  @media (width >= 1024px) {
    .personas > .persona div.image.merchant {
      background-image: url("merchant-2x.45a3eef1.webp");
    }
  }

  .personas > .persona div.image.technician {
    background-image: url("technician-l-2x.95ada8a5.webp");
  }

  @media (width >= 1024px) {
    .personas > .persona div.image.technician {
      background-image: url("technician-2x.ace64d11.webp");
    }
  }

  .personas > .persona div.image.integrator {
    background-image: url("developer-l-2x.a238e110.webp");
  }

  @media (width >= 1024px) {
    .personas > .persona div.image.integrator {
      background-image: url("developer-2x.530fa5b3.webp");
    }
  }
}

.personas > .persona > .content {
  flex-direction: column;
  flex-grow: 1;
  padding: 0 1.5rem 1.5rem;
  display: flex;
}

.personas > .persona > .content > .title {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

@media (width >= 1024px) {
  .personas > .persona > .content > .title {
    letter-spacing: -.025em;
  }
}

.personas > .persona > .content > .title a {
  color: inherit;
  text-decoration-line: none;
}

.personas > .persona > .content > .title a:hover {
  text-decoration-line: none;
}

.personas > .persona > .content > .text {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  margin-top: 1rem;
  margin-bottom: 2rem;
  line-height: 1.75rem;
}

.personas > .persona > .content > .action {
  flex-grow: 1;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

.card {
  border-radius: var(--content-radius);
  break-inside: avoid;
  text-align: center;
  padding: 4rem 1.5rem 2.5rem;
  transition: color .3s cubic-bezier(.4, 0, .2, 1) .1s, background-color .3s cubic-bezier(.4, 0, .2, 1) .1s, border-color .3s cubic-bezier(.4, 0, .2, 1) .1s, text-decoration-color .3s cubic-bezier(.4, 0, .2, 1) .1s, fill .3s cubic-bezier(.4, 0, .2, 1) .1s, stroke .3s cubic-bezier(.4, 0, .2, 1) .1s, opacity .3s cubic-bezier(.4, 0, .2, 1) .1s, box-shadow .3s cubic-bezier(.4, 0, .2, 1) .1s, transform .3s cubic-bezier(.4, 0, .2, 1) .1s, filter .3s cubic-bezier(.4, 0, .2, 1) .1s, -webkit-backdrop-filter .3s cubic-bezier(.4, 0, .2, 1) .1s, backdrop-filter .3s cubic-bezier(.4, 0, .2, 1) .1s;
  position: relative;
}

.card:hover {
  --tw-translate-y: -.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-shadow: 0 0px 8px 0px #0000000d;
  --tw-shadow-colored: 0 0px 8px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (width >= 640px) {
  .card {
    margin-left: .5rem;
    margin-right: .5rem;
  }
}

@media (width >= 768px) {
  .card {
    margin-left: 6rem;
    margin-right: 6rem;
  }
}

@media (width >= 1024px) {
  .card {
    margin-left: 0;
    margin-right: 0;
  }
}

.card a {
  color: inherit;
  text-decoration-line: none;
}

.card a:hover {
  text-decoration-line: none;
}

.card .category {
  background-color: #2626260d;
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
  padding-left: .75rem;
  padding-right: .75rem;
  display: inline-block;
  position: absolute;
  top: 1.5rem;
  right: 0;
}

.card .category span {
  -webkit-user-select: none;
  user-select: none;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
  font-size: .75rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.card .title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

@media (width >= 1024px) {
  .card .title {
    letter-spacing: -.025em;
  }
}

.card .image {
  margin-top: 2rem;
  margin-bottom: -1rem;
  padding-bottom: 2rem;
}

.card + .card {
  margin-top: 2rem;
}

.card-bg-emerald, .card-bg-emerald .category {
  background-color: #0596691a;
}

.card-bg-emerald .category span {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity));
}

.card-bg-sky, .card-bg-sky .category {
  background-color: #0284c71a;
}

.card-bg-sky .category span {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity));
}

.card-bg-neutral, .card-bg-neutral .category {
  background-color: #5252521a;
}

.card-bg-neutral .category span {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

.card-bg-yellow, .card.card-bg-yellow .category {
  background-color: #ca8a041a;
}

.card.card-bg-yellow .category span {
  --tw-text-opacity: 1;
  color: rgb(161 98 7 / var(--tw-text-opacity));
}

.card ul.features {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
  width: 80%;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media (width >= 768px) {
  .card ul.features {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (width >= 1280px) {
  .card ul.features {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.card ul.features li {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
  border-color: #94a3b833;
  border-bottom-width: 1px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.card ul.features li:first-child {
  padding-top: 0;
}

.card ul.features li:last-child {
  border-bottom: none;
}

.testimonial {
  border-radius: var(--content-radius);
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
  flex-direction: column;
  flex: 1;
  padding: 3rem 2rem;
  line-height: 2;
  transition: color .3s cubic-bezier(.4, 0, .2, 1) .1s, background-color .3s cubic-bezier(.4, 0, .2, 1) .1s, border-color .3s cubic-bezier(.4, 0, .2, 1) .1s, text-decoration-color .3s cubic-bezier(.4, 0, .2, 1) .1s, fill .3s cubic-bezier(.4, 0, .2, 1) .1s, stroke .3s cubic-bezier(.4, 0, .2, 1) .1s, opacity .3s cubic-bezier(.4, 0, .2, 1) .1s, box-shadow .3s cubic-bezier(.4, 0, .2, 1) .1s, transform .3s cubic-bezier(.4, 0, .2, 1) .1s, filter .3s cubic-bezier(.4, 0, .2, 1) .1s, -webkit-backdrop-filter .3s cubic-bezier(.4, 0, .2, 1) .1s, backdrop-filter .3s cubic-bezier(.4, 0, .2, 1) .1s;
  display: flex;
  position: relative;
}

.testimonial:hover {
  --tw-translate-y: -.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-shadow: 0 0px 8px 0px #0000000d;
  --tw-shadow-colored: 0 0px 8px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (width >= 640px) {
  .testimonial {
    margin-left: .5rem;
    margin-right: .5rem;
  }
}

@media (width >= 768px) {
  .testimonial {
    margin-left: 6rem;
    margin-right: 6rem;
  }
}

@media (width >= 1024px) {
  .testimonial {
    margin-left: 0;
    margin-right: 0;
  }
}

.testimonial:before {
  content: "";
  fill: #e2e8f0;
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
  top: -1.5rem;
  background: url("data:image/svg+xml; utf8, <svg width='100%' height='100%' viewBox='0 0 4 4' fill='currentColor' xmlns='http://www.w3.org/2000/svg' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'><path d='M1.849,2.575C1.849,2.768 1.787,2.925 1.663,3.045C1.538,3.158 1.381,3.215 1.192,3.215C0.962,3.215 0.773,3.138 0.622,2.985C0.478,2.832 0.406,2.612 0.406,2.325C0.406,2.018 0.491,1.718 0.661,1.425C0.838,1.125 1.084,0.888 1.398,0.715L1.624,1.065C1.453,1.185 1.316,1.318 1.211,1.465C1.113,1.612 1.048,1.782 1.015,1.975C1.074,1.948 1.142,1.935 1.221,1.935C1.404,1.935 1.555,1.995 1.673,2.115C1.79,2.235 1.849,2.388 1.849,2.575ZM3.656,2.575C3.656,2.768 3.594,2.925 3.47,3.045C3.345,3.158 3.188,3.215 2.998,3.215C2.769,3.215 2.579,3.138 2.429,2.985C2.285,2.832 2.213,2.612 2.213,2.325C2.213,2.018 2.298,1.718 2.468,1.425C2.645,1.125 2.89,0.888 3.204,0.715L3.43,1.065C3.26,1.185 3.123,1.318 3.018,1.465C2.92,1.612 2.854,1.782 2.821,1.975C2.88,1.948 2.949,1.935 3.028,1.935C3.211,1.935 3.362,1.995 3.479,2.115C3.597,2.235 3.656,2.388 3.656,2.575Z' style='fill:rgb(226,232,240);fill-rule:nonzero;'/></svg>") no-repeat;
  width: 4rem;
  height: 4rem;
  display: inline-block;
  position: absolute;
  inset-inline-start: 0;
}

.testimonial .quote {
  fill: #e2e8f0;
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
  top: -1.5rem;
  width: 4rem;
  height: 4rem;
  display: inline-block;
  position: absolute;
  inset-inline-start: 0;
}

.testimonial .text {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
  flex-grow: 1;
  line-height: 1.75rem;
}

.testimonial .footer {
  margin-top: 1.5rem;
}

.testimonial .footer .author {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
  margin-top: 1.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.testimonial .footer .company {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
  margin-top: .25rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.testimonial .logo img {
  margin-left: auto;
  margin-right: auto;
}

#contact {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.pricing-card {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
  border-radius: .75rem;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 24rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem;
  transition: color .3s cubic-bezier(.4, 0, .2, 1) .1s, background-color .3s cubic-bezier(.4, 0, .2, 1) .1s, border-color .3s cubic-bezier(.4, 0, .2, 1) .1s, text-decoration-color .3s cubic-bezier(.4, 0, .2, 1) .1s, fill .3s cubic-bezier(.4, 0, .2, 1) .1s, stroke .3s cubic-bezier(.4, 0, .2, 1) .1s, opacity .3s cubic-bezier(.4, 0, .2, 1) .1s, box-shadow .3s cubic-bezier(.4, 0, .2, 1) .1s, transform .3s cubic-bezier(.4, 0, .2, 1) .1s, filter .3s cubic-bezier(.4, 0, .2, 1) .1s, -webkit-backdrop-filter .3s cubic-bezier(.4, 0, .2, 1) .1s, backdrop-filter .3s cubic-bezier(.4, 0, .2, 1) .1s;
  display: flex;
}

.pricing-card:hover {
  --tw-translate-y: -.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-shadow: 0 0px 8px 0px #0000000d;
  --tw-shadow-colored: 0 0px 8px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (width >= 640px) {
  .pricing-card {
    margin-left: .5rem;
    margin-right: .5rem;
  }
}

@media (width >= 768px) {
  .pricing-card {
    margin-left: 6rem;
    margin-right: 6rem;
  }
}

@media (width >= 1024px) {
  .pricing-card {
    margin-left: 0;
    margin-right: 0;
  }
}

.pricing-card > .action {
  flex-grow: 1;
  align-items: flex-end;
  display: flex;
}
/*# sourceMappingURL=index.596606f3.css.map */
