@import "base.css";
@import "header.css";
@import "footer.css";

@media (max-width: 767px) {
    .menu-btn {
        display: flex !important
    }

    .menu-items {
        @apply fixed block py-2 pl-2 w-80 left-0 h-full bg-white overflow-y-auto text-base;
        left: -100vh;
        scrollbar-gutter: stable;
        transition: left 0.3s ease-out;
        top: var(--header-height);
        height: calc(100vh - 80px) - calc(var(--has-banner) * var(--banner-height));
    }
    .menu-items.open {
        left: 0;
    }
}

#jumbo {
    @apply grid lg:grid-cols-2 place-items-center px-8 py-16 lg:py-0
}

header + .container {
    margin-top: var(--header-height);
}

.page-title {
    @apply max-w-screen-xl mx-auto px-8 pt-16 pb-16 text-6xl font-bold tracking-tight;
    margin-top: var(--header-height);
}

h1.page-title + .container {
    @apply pt-8
}

.container.jumbo {
    @apply flex items-center flex-col lg:flex-row;
    margin-top: var(--header-height);
    min-height: calc(100vh - var(--header-height));
}

/* .jumbo img {
    @apply bg-center bg-no-repeat bg-contain content-[url('/src/ekasa/images/printer-m.webp')];
} */

div.feature-list {
    @apply max-w-2xl mx-auto lg:max-w-4xl
}

dl.feature-list {
    @apply grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16
}

dl.feature-list > .item {
    @apply relative pl-16
}

dl.feature-list >.item > dt.title {
    @apply text-lg font-semibold leading-7 text-gray-900
}

dl.feature-list > .item > dd.desc {
    @apply mt-2 text-base leading-7 text-gray-600
}

dl.feature-list > .item > dt.title > .icon {
    @apply absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-green-600
}

dl.feature-list > .item > dt.title > .icon svg {
    @apply w-6 h-6 text-white
}

/* Checklist */

ol.checklist {
    @apply relative border-s border-gray-200
}

ol.checklist > li {
    @apply mb-10 ms-6
}

ol.checklist > li > .icon {
    @apply absolute -start-2.5 flex mt-1 h-5 w-5 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white
}

ol.checklist > li > h3.title {
    @apply text-lg mb-1.5 font-semibold leading-7 text-gray-900 /*leading-none*/
}

ol.checklist > li > p.desc {
    @apply text-base font-normal text-gray-500
}

/**********/

#personas {
    @apply bg-neutral-100
}

.personas {
    @apply flex flex-col gap-8 lg:flex-row
}

.personas > .persona {
    border-radius: var(--content-radius);
    @apply flex flex-col flex-1 sm:mx-2 md:mx-24 lg:mx-0 border border-neutral-300/75
}

.personas > .persona > a.image {
    margin: .5rem;
}

.personas > .persona div.image {
    border-radius: calc(var(--content-radius) - 0.375rem);
    background-size: auto 100%;
    background-position: center;
    @apply h-64 sm:h-80 lg:h-72
}

.personas > .persona div.image.merchant {
    @apply bg-[url('../images/personas/merchant-l.webp')] lg:bg-[url('../images/personas/merchant.webp')] 
}

.personas > .persona div.image.technician {
    @apply bg-[url('../images/personas/technician-l.webp')] lg:bg-[url('../images/personas/technician.webp')] 
}

.personas > .persona div.image.integrator {
    @apply bg-[url('../images/personas/developer-l.webp')] lg:bg-[url('../images/personas/developer.webp')] 
}

/* Retina */
@media
(-webkit-min-device-pixel-ratio: 2), 
(min-resolution: 144dpi) { 
    .personas > .persona div.image.merchant {
        @apply bg-[url('../images/personas/merchant-l-2x.webp')] lg:bg-[url('../images/personas/merchant-2x.webp')] 
    }
    
    .personas > .persona div.image.technician {
        @apply bg-[url('../images/personas/technician-l-2x.webp')] lg:bg-[url('../images/personas/technician-2x.webp')] 
    }
    
    .personas > .persona div.image.integrator {
        @apply bg-[url('../images/personas/developer-l-2x.webp')] lg:bg-[url('../images/personas/developer-2x.webp')] 
    }
}

.personas > .persona > .content {
    @apply pt-0 pl-6 pr-6 pb-6 flex flex-col grow
}

.personas > .persona > .content > .title {
    @apply mt-4 text-2xl lg:tracking-tight font-bold font-semibold
}

.personas > .persona > .content > .title a {
    @apply text-inherit no-underline hover:no-underline
}

.personas > .persona > .content > .text {
    @apply mt-4 mb-8 text-gray-600 leading-7
}

.personas > .persona > .content > .action {
    @apply flex grow items-end justify-end
}

#features {
}

.card {
    border-radius: var(--content-radius);
    @apply relative px-6 pt-16 pb-10 break-inside-avoid text-center lg:mx-0 md:mx-24 sm:mx-2
    transition ease-in-out delay-100 hover:-translate-y-0.5 duration-300 hover:shadow-[0_0px_8px_0px_rgba(0,0,0,0.05)]
}

.card a {
    @apply text-inherit no-underline hover:no-underline
}

.card .category {
    @apply absolute top-6 right-0 rounded-l-md inline-block px-3 bg-neutral-800/5
}

.card .category span {
    @apply select-none text-xs font-medium uppercase leading-6 text-neutral-700
}

.card .title {
    @apply text-2xl lg:tracking-tight font-bold font-semibold
}

.card .image {
    @apply mt-8 -mb-4 pb-8
}

.card + .card {
    @apply mt-8
}

.card-bg-emerald {
    @apply bg-emerald-600/10
}

.card-bg-emerald .category {
    @apply bg-emerald-600/10
}

.card-bg-emerald .category span {
    @apply text-emerald-700
}

.card-bg-sky {
    /*background-color: #e8f0f9;*/
    @apply bg-sky-600/10
}

.card-bg-sky .category {
    @apply bg-sky-600/10
}

.card-bg-sky .category span {
    @apply text-sky-700
}

.card-bg-neutral {
    @apply bg-neutral-600/10
}

.card-bg-neutral .category {
    @apply bg-neutral-600/10
}

.card-bg-neutral .category span {
    @apply text-neutral-700
}

.card-bg-yellow { 
    @apply bg-yellow-600/10
}

.card.card-bg-yellow .category {
    @apply bg-yellow-600/10
}

.card.card-bg-yellow .category span {
    @apply text-yellow-700
}

.card ul.features {
    @apply w-4/5 mx-auto mt-8 text-lg md:text-base xl:text-base text-slate-600
}

.card ul.features li {
    @apply py-4 border-b border-slate-400/20 text-slate-600
}

.card ul.features li:first-child {
    @apply pt-0
}

.card ul.features li:last-child {
    border-bottom: none;
}

/* Testmonials */

#testimonials {
}

.testimonial {
    border-radius: var(--content-radius);
    @apply flex flex-col relative flex-1 lg:mx-0 md:mx-24 sm:mx-2 px-8 py-12 border border-slate-200 text-slate-600 leading-loose
    transition ease-in-out delay-100 hover:-translate-y-0.5 duration-300 hover:shadow-[0_0px_8px_0px_rgba(0,0,0,0.05)]
}

.testimonial::before {
    content: '';
    background: transparent url("data:image/svg+xml; utf8, <svg width='100%' height='100%' viewBox='0 0 4 4' fill='currentColor' xmlns='http://www.w3.org/2000/svg' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'><path d='M1.849,2.575C1.849,2.768 1.787,2.925 1.663,3.045C1.538,3.158 1.381,3.215 1.192,3.215C0.962,3.215 0.773,3.138 0.622,2.985C0.478,2.832 0.406,2.612 0.406,2.325C0.406,2.018 0.491,1.718 0.661,1.425C0.838,1.125 1.084,0.888 1.398,0.715L1.624,1.065C1.453,1.185 1.316,1.318 1.211,1.465C1.113,1.612 1.048,1.782 1.015,1.975C1.074,1.948 1.142,1.935 1.221,1.935C1.404,1.935 1.555,1.995 1.673,2.115C1.79,2.235 1.849,2.388 1.849,2.575ZM3.656,2.575C3.656,2.768 3.594,2.925 3.47,3.045C3.345,3.158 3.188,3.215 2.998,3.215C2.769,3.215 2.579,3.138 2.429,2.985C2.285,2.832 2.213,2.612 2.213,2.325C2.213,2.018 2.298,1.718 2.468,1.425C2.645,1.125 2.89,0.888 3.204,0.715L3.43,1.065C3.26,1.185 3.123,1.318 3.018,1.465C2.92,1.612 2.854,1.782 2.821,1.975C2.88,1.948 2.949,1.935 3.028,1.935C3.211,1.935 3.362,1.995 3.479,2.115C3.597,2.235 3.656,2.388 3.656,2.575Z' style='fill:rgb(226,232,240);fill-rule:nonzero;'/></svg>") no-repeat;
    @apply absolute inline-block -top-6 start-0 size-16 text-gray-100 fill-slate-200
}

.testimonial .quote {
    
    @apply absolute inline-block -top-6 start-0 size-16 text-gray-100 fill-slate-200
}

.testimonial .text {
    @apply grow text-slate-800 leading-7 text-center
}

.testimonial .footer {
    @apply mt-6
}
.testimonial .footer .author {
    @apply text-slate-800 font-semibold text-center text-xl mt-6
}

.testimonial .footer .company {
    @apply text-slate-500 font-normal text-center text-sm mt-1
}

.testimonial .footer .logo {
}

.testimonial .logo img {
    @apply mx-auto
}

/* Contact */
#contact {
    @apply bg-neutral-100
}

#faq {
}

.pricing-card {
    @apply flex flex-1 flex-col rounded-xl mx-auto text-center p-6 lg:mx-0 md:mx-24 sm:mx-2 border border-slate-200 text-slate-900 max-w-sm w-full
    transition ease-in-out delay-100 hover:-translate-y-0.5 duration-300 hover:shadow-[0_0px_8px_0px_rgba(0,0,0,0.05)]
}

.pricing-card > .action {
    @apply flex grow items-end
}